import image13 from "./img/image-13.png";
import image12 from "./img/image-12.png";
import image15 from "./img/image-15.png";

// import ellipse from "./img/ellipse4.svg";
import ellipse from "./img/Ellipse 5.png";
import dayjs from "dayjs";

import kakaoLoginImg from "./img/kakaoLoginImg.png";
import googleLoginImg from "./img/googleLoginImg.svg";

import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import "./Login.css";

const Login = () => {
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const dday = leftdays();
  const [isWebView, setIsWebView] = useState(false);
  const [webViewType, setWebViewType] = useState("");

  //소셜 로그인(카카오, 구글)
  async function signInWithKakao() {
    try {
      const { data, error } = await supabaseClient.auth.signInWithOAuth({
        provider: "kakao",
        // options: {
        //   redirectTo:
        //     process.env.REACT_APP_FRONTEND_REDIRECT_URL /* 로그인 후에 redirect 될 페이지 */,
        // },
      });

      // if (error) {
      //   // console.error("Kakao OAuth sign-in error:", error.message);
      // } else {
      //   // console.log("Kakao OAuth sign-in successful:", data);
      //   // console.log("Kakao OAuth sign-in successful:");
      // }

      handlePostLogin(user);
    } catch (error) {
      // console.error("Unexpected error during Kakao OAuth sign-in:", error);
    }
  }
  async function signInWithGoogle() {
    try {
      const { data, error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
        // options: {
        //   redirectTo: process.env.REACT_APP_FRONTEND_REDIRECT_URL,
        // },
      });
      // if (error) {
      //   // console.error("Google OAuth sign-in error:", error.message);
      // } else {
      //   // console.log("Google OAuth sign-in successful:", data);
      //   // console.log("Google OAuth sign-in successful");
      // }
      handlePostLogin(user);
    } catch (error) {
      // console.error("Unexpected error during Google OAuth sign-in:", error);
    }
  }

  async function handlePostLogin(user) {
    if (!user) {
      return;
    }

    const { data, error } = await supabaseClient
      .from("users")
      .select()
      .eq("user_id", user.id)
      .single();

    if (error || !data) {
      //회원가입 정보 없으면 '/policy'로 이동
      // console.log("go to policy");
      //   navigate(process.env.REACT_APP_FRONTEND_REDIRECT_URL);
    } else {
      //회원가입 정보 있으면 '/main'로 이동
      // console.log("go to main");
      //   navigate(process.env.REACT_APP_FRONTEND_REDIRECT_URL2);
    }
  }

  useEffect(() => {
    async function checkLogin() {
      const authInfo = await supabaseClient.auth.getSession();
      const session = authInfo.data.session;

      if (session == null) {
        // console.log("로그인 해주세요");
      } else {
        // console.log("이미 로그인 되었습니다");
        navigate("/main");
      }
    }
    checkLogin();

    async function getUserData() {
      await supabaseClient.auth.getUser().then(async (value) => {
        if (value.data?.user) {
          const { data: userData, error } = await supabaseClient
            .from("users")
            .select()
            .eq("user_id", value.data.user.id);
          if (error) {
            // console.log(error);
          } else {
            setUser({
              id: value.data.user.id,
              // 최초 로그인시 아래 정보가 없으니까, 주석 처리 하였음.
              // nickname: value.data.user.nickname,
              // userId: value.data.user.user_id,
            });
          }
        }
      });
    }
    getUserData();
  }, [supabaseClient]);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("kakaotalk")) {
      setIsWebView(true);
      setWebViewType("카카오톡");
    } else if (userAgent.includes("instagram")) {
      setIsWebView(true);
      setWebViewType("인스타그램");
    }
  }, []);

  const openInBrowser = () => {
    // //현재 URL을 기본 브라우저에서 새 탭으로 열기
    // window.open(window.location.href, "_blank").focus();

    // Web Share API를 지원하는지 확인
    if (navigator.share) {
      navigator
        .share({
          title: "TIMECAPSULE", // 공유될 제목 (선택사항)
          url: window.location.href, // 현재 페이지의 URL
        })
        .then(() => {
          console.log("공유 성공!");
        })
        .catch((error) => {
          console.error("공유 중 에러 발생:", error);
        });
    } else {
      // Web Share API를 지원하지 않는 경우 자동으로 클립보드에 URL 복사

      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          alert(
            "URL이 클립보드에 복사되었습니다.\n크롬, 사파리 등 기본 브라우저에서 붙여넣기 해주세요 :)"
          );
        })
        .catch((error) => {
          alert(
            "클립보드 복사에 실패했습니다.\nURL을 직접 복사하여 크롬, 사파리 등 기본 브라우저에서 접속해주세요 :)"
          );
        });
    }
  };
  return (
    <div className="componentBackground">
      {isWebView ? (
        <>
          <div className="webview-alert-div">
            <div className="webview-alert-text">
              {webViewType} 웹뷰 접속시 구글 로그인이 안될 수 있습니다.
              <br />
              최적의 사용 경험을 위해 기본 브라우저에서 열어주세요 !
            </div>
            <div className="webview-alert-button-div">
              <button className="webview-alert-button" onClick={openInBrowser}>
                열기
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div id="page">
        <img src={image13} id="image-13" alt="capsule-1" />
        <img src={image12} id="image-12" alt="capsule-2" />
        <img src={image15} id="image-15" alt="capsule-3" />
        <img src={ellipse} className="title-ellipse" alt="ellipse" />

        <div id="content">
          <div id="login-title">
            TIME
            <br />
            CAPSULE
          </div>
          <div id="description">1년 뒤 나에게 보내는 선물</div>
          <hr id="white-line" />
          <div id="login-dday">D-{dday}</div>
        </div>
        <div id="unsealed-date">Unsealed December 31, 2024</div>

        <div className="sns">
          <div className="sns-google">
            <img
              className="googleButton"
              src={googleLoginImg}
              alt="google-login-button"
              onClick={signInWithGoogle}
            />
          </div>
          <div className="sns-kakao">
            <img
              className="kakaoButton"
              src={kakaoLoginImg}
              alt="kakao-login-button"
              onClick={signInWithKakao}
            />
          </div>
        </div>
        <div className="login-footer"></div>
      </div>
    </div>
  );
};

export default Login;

const leftdays = () => {
  const today = dayjs();
  const endOfYear = dayjs().endOf("year");
  const daysLeft = endOfYear.diff(today, "day");
  if (daysLeft === 0) {
    return "DAY";
  } else {
    return daysLeft;
  }
};
